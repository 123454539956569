<template>
  <div class="topbar">
    <ul class="topbar-buttons">
      <li><i class="fa-solid fa-bell"></i></li>
      <li v-if="checkPermission('users')" @click="goToUsers()" ><i class="fa-solid fa-wrench"></i></li>
      <li v-if="checkPermission('roles')" @click="goToRoles()" ><i class="fa-solid fa-hammer"></i></li>
      <li @click="goToOptions()" ><i class="fa-solid fa-gear"></i></li>
      <li @click="$store.dispatch('logout')">
        <i class="fa-solid fa-power-off"></i>
      </li>
    </ul>
  </div>
</template>

<script>
import {isAllowed} from '@/acl/methods'
export default {
  methods: {
    goToOptions: function () {
        this.$router.push('/opciones')
    },
    goToUsers: function () {
        this.$router.push('/users')
    },
    goToRoles: function () {
        this.$router.push('/roles')
    },
    checkPermission: function (module) {
        return isAllowed(this.$store.getters.user, module)
    }
  },
};
</script>
