export default [
  {
    icon: "fa-solid fa-gauge-high",
    title: "Dashboard",
    to: "/dashboard",
    children: null,
  },
  {
    icon: "fa-solid fa-chevron-right",
    title: "Contenidos",
    module: 'contenidos',
    children: [
      {
        icon: "fa-solid fa-columns",
        title: "Menu",
        to: "/categorias",
        module: 'categorias',
        children: null,
      },
      {
        icon: "fa-solid fa-trophy",
        title: "Categorías Torneos",
        to: "/categorias-torneos",
        children: null,
      },
      {
        icon: "fa-solid fa-gavel",
        title: "Jueces",
        to: "/jueces",
        children: null,
      },
      {
        icon: "fa-solid fa-people-group",
        title: "Comite Ejecutivo",
        to: "/comite-ejecutivo",
        children: null,
      },
      {
        icon: "fa-solid fa-circle-play",
        title: "Videos",
        to: "/videos",
        children: null,
      },
      {
        icon: "fa-solid fa-images",
        title: "Galerías",
        to: "/galerias",
        module: 'galerias',
        children: null,
      },
      {
        icon: "fa-solid fa-link",
        title: "Links de Interes",
        to: "/links",
        module: 'links',
        children: null,
      },
    ]
  },
  {
    icon: "fa-solid fa-chevron-right",
    title: "Entradas",
    module: 'contenidos',
    children: [{
        icon: "fa-solid fa-newspaper",
        title: "Entradas Generales",
        to: "/contenidos",
        module: 'contenidos',
        children: null,
      },{
        icon: "fa-solid fa-newspaper",
        title: "Reglamentos",
        to: "/contenidos-reglamentos",
        module: 'contenidos',
        children: null,
      },
      {
        icon: "fa-solid fa-newspaper",
        title: "Blog",
        to: "/contenidos-blog",
        module: 'contenidos',
        children: null,
      },
      {
        icon: "fa-solid fa-newspaper",
        title: "Reglamento Adiestramiento",
        to: "/contenidos-reglamento-adiestramiento",
        module: 'contenidos',
        children: null,
      },
      {
        icon: "fa-solid fa-newspaper",
        title: "Reglamento Torneo",
        to: "/contenidos-reglamento-torneo",
        module: 'contenidos',
        children: null,
      },
      {
        icon: "fa-solid fa-newspaper",
        title: "Información Util",
        to: "/contenidos-informacion-util",
        module: 'contenidos',
        children: null,
      },
      {
        icon: "fa-solid fa-newspaper",
        title: "Ultimas Noticias",
        to: "/contenidos-ultimas-noticias",
        module: 'contenidos',
        children: null,
      },
      {
        icon: "fa-solid fa-newspaper",
        title: "Resultados",
        to: "/contenidos-resultados",
        module: 'contenidos',
        children: null,
      },
      {
        icon: "fa-solid fa-newspaper",
        title: "Institución",
        to: "/contenidos-institucion",
        module: 'contenidos',
        children: null,
      },
    ],
  },
  {
    icon: "fa-solid fa-images",
    title: "Galerías Publicas",
    to: "/galeriasPublicas",
    module: 'galerias',
    children: null,
  },
  {
    icon: "fas fa-calendar",
    title: "Calendario",
    to: "/calendario",
    module: 'calendario',
    children: null,
  },
  {
    icon: "fa-solid fa-address-book",
    title: "Contactos",
    to: "/contactos",
    children: null,
  },
  /*
  {
    icon: "icon fa-solid fa-trophy",
    title: 'Torneos',
    children: [
      {
        icon: "icon fa-solid fa-plus",
        title: "Inscripcion",
        to: "/torneos/inscripcion",
      }
    ]
  },
  */
  {
    icon: "icon fa-solid fa-book",
    title: 'Revistas',
    children: [
      {
        icon: "icon fa-solid fa-plus",
        title: "Subir",
        to: "/revistas/subir",
      }
    ]
  },
];
