<template>
  <div class="dashboard-layout">
    <Sidebar />
    <div class="main">
      <Topbar />
      <div class="content-wrapper">
        <div class="content">
          <router-view></router-view>
        </div>
        <div class="footer">
          Copyright &reg; {{ new Date().getFullYear() }} - Gavion CMS - Powered
          by
          <a href="http://trememote.com.ar" target="_blank">&nbsp;Trememote</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Topbar from "@/components/Topbar.vue";
import {token_expired} from '@/acl/methods'

export default {
  components: {
    Sidebar,
    Topbar,
  },
  beforeMount() {
    this.$store.dispatch("setCredentials");
    const isLoggedIn = this.$store.getters.isLoggedIn;

    if (!isLoggedIn) {
      this.$router.push("/login");
    } else {
      // this.$store.dispatch("getUnreadNotifications");
      if (!token_expired(this.$store.getters.user)){
       this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
       this.$store.dispatch("logout");
      }
    }

  },
};
</script>
